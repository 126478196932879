import React from 'react'
import { Typography } from '@mui/material';

const About = () => {
  return (
    <div>
       <Typography>================================== ================================== About Page========================= ==========</Typography>
    </div>
  )
}

export default About
