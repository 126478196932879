import React from 'react'
import Sidenav from './Sidebar/sidenav'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { styled, useTheme, alpha, ThemeProvider, createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});
const ToggleThemeButton = styled('button')(({ theme }) => ({
  position: 'fixed',
  top: '80px',
  right: '-10px',
  zIndex: theme.zIndex.drawer + 2,
  padding: '10px',
  backgroundColor: theme.palette.info.dark,
  color: theme.palette.text.primary,
  borderRadius: '10%',
  transition: '0.3s',
  cursor: 'pointer',
  color: '#fff',
  width: '0px',
  height: '40px',
  boxShadow: theme.shadows[2],
  '&:hover': {
    width: 'auto',
  }
}));
const App = () => {
  const themePreference = localStorage.getItem('theme') || 'dark';
  const theme = themePreference === 'dark' ? darkTheme : lightTheme;

  const handleThemeToggle = () => {
    const newMode = theme.palette.mode === 'dark' ? 'light' : 'dark';
    localStorage.setItem('theme', newMode); // Save the theme preference in local storage or state management
    window.location.reload();

  };
  return (
    <ThemeProvider theme={theme}>
      <ToggleThemeButton onClick={handleThemeToggle}>
        {theme.palette.mode === 'dark' ? 'Light Theme' : 'Dark Theme'}
      </ToggleThemeButton>
      <Router>
        <Sidenav />
      </Router>
      
    </ThemeProvider>
  )
}
export default App
