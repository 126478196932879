import React from 'react'
import { Typography } from '@mui/material';

const Settings = () => {
  return (
    <div>
      <Typography>lkdjfskdfklsdklfmsdklmfklsdmfklmlkm</Typography>
    </div>
  )
}

export default Settings
